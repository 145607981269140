import React, { lazy, Suspense } from "react";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./components/loading";

import { Box } from "@mui/material";

// Lazy load components
const Error = lazy(() => import("./pages/404"));
const AppUser = lazy(() => import("./pages/appUser"));
const AppUserGroup = lazy(() => import("./pages/appUserGroup"));
const AppUserGroupImport = lazy(() =>
  import("./pages/form/appUserGroupImport")
);

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <Box p={3}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="*" element={<Error />} />
              <Route path="/user" element={<AppUser />} />
              <Route path="/user-group" element={<AppUserGroup />} />
              <Route path="/user-group/form" element={<AppUserGroupImport />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </Box>
    </ApolloProvider>
  );
}
